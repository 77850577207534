<template>
    <div>
      <div class="sidebar" :class="{show:visible}">
        <div @click="visible=!visible" class="over-icon"><svg t="1693493948213" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4186"><path d="M937.386667 488.106667L772.266667 372.48c-12.8-9.386667-30.293333-6.826667-40.106667 5.546667-3.84 4.693333-5.546667 10.666667-5.546667 17.066666v233.813334c0 22.613333 25.6 36.693333 45.653334 22.613333l165.546666-115.626667c14.08-14.08 14.08-36.693333-0.426666-47.786666zM914.346667 213.333333h-785.066667c-18.773333 0-34.133333-15.36-34.133333-34.133333s15.36-34.133333 34.133333-34.133333h785.066667c18.773333 0 34.133333 15.36 34.133333 34.133333s-14.933333 34.133333-34.133333 34.133333zM914.346667 878.933333h-785.066667c-18.773333 0-34.133333-15.36-34.133333-34.133333s15.36-34.133333 34.133333-34.133333h785.066667c18.773333 0 34.133333 15.36 34.133333 34.133333s-14.933333 34.133333-34.133333 34.133333zM624.213333 435.2h-494.933333c-18.773333 0-34.133333-15.36-34.133333-34.133333s15.36-34.133333 34.133333-34.133334h494.933333c18.773333 0 34.133333 15.36 34.133334 34.133334s-14.933333 34.133333-34.133334 34.133333zM624.64 657.066667H129.28c-18.773333 0-34.133333-15.36-34.133333-34.133334s15.36-34.133333 34.133333-34.133333h495.36c18.773333 0 34.133333 15.36 34.133333 34.133333v0.426667c-0.426667 18.346667-15.36 33.706667-34.133333 33.706667z" fill="#4C4C4C" p-id="4187"></path></svg></div>
        <h3>目录</h3>
        <template>
          <RecursiveListItem :item="treeData[0]"  class="anchor-list" />
        </template>
      </div>
      
    </div>
  </template>
  
  <script>
  const RecursiveListItem = () => import("./RecursiveListItem");
  export default {
    components: {
      RecursiveListItem
    },
    props: {
      treeData:[]
    },
    data() {
        return{
            visible:true,
            items:[]
        }
    },
    mounted() {
      console.log(this.treeData);
      setTimeout(() => {
          this.visible = false
      }, 1000);
    },
  };
  </script>
  
  <style>
  .sidebar {
    display: flex;
    flex-direction: column;
    transition: .3s;
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    width: 300px;
    height: 100%;
    background-color: var(--background);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
    padding: 1em;
    transform: translateX(-92%);
  }
  .sidebar.show{
    transform: translateX(-0);

  }
  .sidebar ul {
    margin: 0;
    width: 100%;
    padding: 0 15px;
    list-style: none;
  }
  .sidebar .anchor-list{
    list-style: none;
    text-align: left;
    overflow-y: scroll;
  }
  .anchor-list span{
    font-family:'Fira Code';
    cursor: pointer;
    font-size: 0.9em;
    line-height: 1.4em;
    display: block;
    color:var(--fontColor);
    text-decoration: none;
  }
  .anchor-list span:hover {
    color: orange;
    }
  .sidebar .content {
    margin-left: 220px;
    padding: 20px;
  }
  .over-icon{
    position: absolute;
    width: 36px;
    height: 36px;
    top:64px;
    right: -31px;
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
    background: #fff;
    box-shadow: 3px 0px 0px 0 rgba(0, 0, 0, 0.16);
  }
  .over-icon:hover svg{
    fill: black;
  }
  .sidebar span[level="0"]{
    line-height: 1.4em;
    margin: .6em .2em;
    font-size: 1.2em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
  }
  .sidebar span[level="2"] {
    line-height: 1.2em;
    font-size: 1em;
    margin: .3em;
    font-weight: 500;
  }

  .sidebar span[level="3"] {
    line-height: 1.2em;
    font-size: .8em;
    font-weight: 300;
  }
  @media screen and (max-width: 500px) {
    .sidebar{
      width: 75% !important;
      transform: translateX(-100%);
    }
  }
  </style>